body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'schluber','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'schluber',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, #root {
  height: 100%;
  background-color: black;
}
div#tsparticles {
  height: 100%;
}

span.typewriter {
  border-right: .05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

.formStyle{
  width: 100%;
}
.greenLizardColor{
  color:#2fbf71;
}


@font-face {
  font-family: 'ristretto';
  src: local('ristretto'), url("./assets/ristretto.woff") format('woff');
  font-display: swap;
}
.flip{
  transition: 0.3s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  
}
.flip:hover{
  transform: rotateY(90deg);

}